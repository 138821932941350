@use "../global" as*;

.l-header {
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    padding: 12px 24px;
    background-color: $clW;
    border-top: 3px solid $clblack;
    border-bottom: 3px solid $clblack;

    @include mq () {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        top: 0;
        padding: 24px;
        border-radius: 0;
        height: auto;
    }

    @include mq (sp) {
        padding: 16px 12px;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: auto;
        max-width: 1280px;

        @include mq () {
            margin: 0;
        }

        @include mq (sp) {
            padding: 0;
        }
    }

    &__logo {
        line-height: 0;
        margin-bottom: 0;
        padding-left: 20px;

        a {
            line-height: 0;
            max-width: 240px;
            display: block;

            img {
                width: 100%;
            }
        }

        @include mq () {
            padding-left: 0;
        }

        @include mq (sp) {
            p {
                font-size: 1rem;
                text-align: center;
            }

            a {
                max-width: 150px;
            }
        }
    }

    &__content {
        display: flex;
        align-items: center;
    }

    &__nav {
        @include mq () {
            position: fixed;
            top: 0;
            right: 0;
            z-index: 20;
            width: 100%;
            height: 100vh;
            background-color: $clW;
            flex-direction: column;
            align-items: center;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.5s, visibility 0.5s;
        }
    }

    &__nav-list {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;
        margin-bottom: 0;
        margin-right: 24px;
        list-style: none !important;

        li {
            font-size: 1.6rem;
           

            a {
                color: $clText;
                text-decoration: none;
                font-weight: 700;
                font-size: 1.6rem;

                &:hover {
                    transition: 0.2s;
                }
            }
        }

        @include mq () {
            flex-direction: column;
            margin-top: 80px;
            gap: 0px;
            margin-right: 0;
            width: 100%;

            li {
                border: none;
                border-bottom: 1px solid $clblack;
                width: 100%;
                text-align: center;
                margin-bottom: 0 !important;

                &:first-child {
                    border-top: 1px solid $clblack;
                }

                &:last-child {
                    border-right: none;
                }


                a {
                    color: $clText;
                    font-size: 1.6rem;
                    display: block;
                    padding: 20px 24px;
                }
            }

        }
        @include mq (sp) {
            margin-top: 60px;
        }
    }


    &__nav-contact {
        a {
            color: $clred;
            display: block;
            border-radius: 10px;
            border: 2px solid $clText;
            text-align: center;
            font-weight: 700;
            padding: 8px 9px 6px;

            .icon {
                width: 30px;
                margin: auto;
                margin-bottom: 5px;

                img {
                    width: 100%;
                }
            }

        }

        @include mq () {
            position: absolute;
            right: calc(24px + 50px);
        }

        @include mq (sp) {
            right: calc(12px + 32px);

            a {
                border: none;

                .icon {
                    width: 24px;
                    margin-bottom: 3px;
                }

                p {
                    font-size: 12px;
                }
            }
        }

    }



    .current {
        a {
            color: $clred;
        }

        @include mq () {
            a {
                color: $clText;
            }
        }

    }

    &.is-open {
        .l-header__nav {
            opacity: 1;
            visibility: visible;

            .l-header__nav-main {
                opacity: 1;
                visibility: visible;
            }

            .l-header__nav-inner {
                opacity: 1;
                visibility: visible;
            }

            .l-header__nav-list-sub {
                opacity: 1;
                visibility: visible;
            }
        }

    }

    /*---------------------------------------
  ハンバーガーメニュー
  ---------------------------------------*/
    &__ham {
        display: none;

        @include mq () {
            display: block;
            line-height: 0;
            position: absolute;
            right: 24px;
            bottom: 24px;
        }

        @include mq (sp) {
            right: 16px;
            bottom: 12px;
        }
    }



    &__ham-btn {
        width: 24px;
        height: 32px;
        position: relative;
        border: none;
        z-index: 25;

        &:after {
            content: "Menu";
            position: absolute;
            top: 27px;
            left: 50%;
            transform: translateX(-50%);
            color: $clA;
            font-size: 1.2rem;
            font-weight: 700;
        }

        span {
            display: block;
            width: 24px;
            height: 2px;
            position: absolute;
            right: 0;
            background-color: $clA;
            border-radius: 100px;
            transition: opacity 0.3s, top 0.5s, transform 0.5s;

            &:nth-of-type(1) {
                top: 0px;
            }

            &:nth-of-type(2) {
                width: 24px;
                top: 8px;
                opacity: 1;
            }

            &:nth-of-type(3) {
                top: 16px;
            }
        }
    }


    &.is-open {
        .l-header__ham-btn {
            background-color: initial;

            &:after {
                content: "Close";
            }

            span {
                background-color: $clA;
                z-index: 20;

                &:nth-of-type(1) {
                    transform: translateX(-50%) rotate(45deg);
                    top: 8px;
                    left: 50%;
                }

                &:nth-of-type(2) {
                    opacity: 0;
                }

                &:nth-of-type(3) {
                    transform: translateX(-50%) rotate(-45deg);
                    top: 8px;
                    left: 50%;
                }
            }
        }
    }
}