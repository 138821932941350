@use "../../global" as *;

.index {
  .p-voice-case {
    padding-top: 70px;
    padding-bottom: 70px;

    @include mq (sp) {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    &__wrapper {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 80px;
      max-width: 960px;
      margin: auto;

      @include mq () {
        gap: 40px;
      }
    }

    &__image {
      max-width: 430px;

      img {
        width: 100%;
      }

      @include mq (sp) {
        max-width: 90%;
        margin: auto;
      }
    }

  }
}