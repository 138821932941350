@use "../../global" as *;

.pd {
    padding-left: 24px;
    padding-right: 24px;
}

.flex-c {
    display: flex;
    justify-content: center;
}

.text-c {
    text-align: center;
}

.width-pc-70 {
    width: 70%;
    margin: auto;

    @include mq (sp) {
        width: 100%;
    }
}

.width-pc-80 {
    width: 80%;
    margin: auto;

    @include mq (sp) {
        width: 100%;
    }
}

.back-ground__green {
    background-color: $clgreen;
}

.back-ground__pink {
    background-color: $clpink;
}

.font-color__green {
    color: $clgreen;
}

.font-color__pink {
    color: $clpink;
}

.font-color__white {
    color: $clW;
}

.font-color__blue {
    color: $clblue;
}

.font-color__red {
    color: $clred;
}

.border-color-b__red {
    border-bottom: 2px solid $clred !important;
}

.border-color-b__pink {
    border-bottom: 2px solid $clpink !important;
}

.border-color-b__blue {
    border-bottom: 2px solid $clblue !important;
}

.border-color-b__purple {
    border-bottom: 2px solid $clpurple !important;
}

.pl-12 {
    padding-left: 12px !important;
}

.list-marker__green {
    li::marker {
        color: $clgreen !important;
    }
}

.list-marker__pink {
    li::marker {
        color: $clpink !important;
    }
}

.list-marker__blue {
    li::marker {
        color: $clblue !important;
    }
}

.list-marker__purple {
    li::marker {
        color: $clpurple !important;
    }
}

.list-marker__red {
    li::marker {
        color: $clred !important;
    }
}

.justify-center__sp {
    @include mq (sp) {
        justify-content: center !important;
    }
}

.link-pink {
    a {
        color: $clpink;
        text-decoration: revert;
    }
}

.text-red {
    color: $clred;
}

.text-blue {
    color: $clblue;

}

.row-reverse {
    flex-direction: row-reverse;
}

.strong {
    font-weight: 700;
}


.big {
    font-size: 1.5em;
}

.small {
    font-size: 0.8em;

}

.middle {
    font-size: 1.2em;
}

.border-red {
    border-bottom: 3px solid $clred;
    padding-bottom: 3px;
}

.border-blue {
    border-bottom: 2px solid $clblue;
}

.waku {
    border: 1px solid $clblack;
    padding: 12px;
    border-radius: 10px;
}

.w80 {
    width: 80%;
    margin: auto;

    @include mq (sp) {
        width: 100%;
    }
}