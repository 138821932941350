@use "../../global" as *;

.c-text {
  margin-bottom: 40px;

  p {
    font-size: 1.8rem;
    text-align: center;
    line-height: 2;

  }

  @include mq () {
    p {
      font-size: 1.6rem;
    }

  }

  @include mq (sp) {
    margin-bottom: 30px;

    p {
      font-size: 1.4rem;
    }

  }
}