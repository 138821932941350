@use "../../global" as *;

.c-button {
  text-align: center;
  max-width: 480px;
  margin: auto;

  a {
    padding: 16px 12px;
    border-radius: 10px;
    position: relative;
    display: block;
    width: 100%;
    margin: auto;
    margin-top: 20px;
    font-weight: 500;
    border: 1px solid $clblack;
    background-color: $clW;


    &:after {
      content: "";
      display: inline-block;
      background-image: url(../img/common/arrow.svg);
      background-size: contain;
      background-repeat: no-repeat;
      width: 6px;
      height: 12px;
      position: absolute;
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @include mq (sp) {
    a {
      padding: 18px 0;
    }

  }
}