@use "../../global" as *;

.lower {
  .p-lower-hp {
    .main-text {
      margin-bottom: 24px;
      line-height: 1.4;
      font-size: 2.4rem;
      text-align: center;

      @include mq (sp) {
        margin-bottom: 16px;
        font-size: 2.0rem;
      }
    }

    &__list {
      margin-bottom: 2em;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3%;


      .img {
        width: 60%;

        @include mq (tb) {
          width: 80%;
        }

        @include mq (sp) {
          width: 100%
        }

        img {
          width: 100%;
        }
      }

      ul {
        // list-style: none;
        font-size: 2.0rem;
        width: 50%;

      }
    }

    &__answer {
      font-size: 2.4rem;

      @include mq (sp) {
        font-size: 2.0rem;
      }
    }

    &__qa {
      width: 50%;

      @include mq (tb) {
        width: 80%;
      }

      @include mq (sp) {
        width: 100%;
      }

      ul {
        list-style: none;
        margin-left: 0;
      }

      li {
        margin-bottom: 24px;
      }

      span {
        // color: $clred;
        margin-right: 5px;
      }

      h3 {
        border-bottom: 2px solid;
        padding-bottom: 8px;
        margin-bottom: 12px;
        font-size: 2.0rem;
        color: $clblue;
      }

    }

    &__contact {
      text-align: center;

      h3 {
        color: $clred;
        line-height: 1.4;

        .br {
          display: none;
        }

        @include mq (sp) {
          .br {
            display: block;
          }
        }

      }

      .button {
        max-width: 420px;
      }
    }

    h4 {
      font-size: 2.4rem;

      @include mq (sp) {
        font-size: 2.0rem;
      }
    }

    &__philosophy {
      margin-bottom: 60px;
    }

    h5 {
      font-size: 2.0rem;

      @include mq (sp) {
        font-size: 1.8rem;
      }
    }

    &__philosophy-image {
      width: 60%;
      margin: auto;
      margin-bottom: 24px;

      img {
        width: 100%;
      }

      @include mq () {
        width: 80%;
      }

      @include mq (sp) {
        width: 100%;
      }
    }

    &__philosophy-title {
      line-height: 1.6;

      .br {
        display: none;
      }

      @include mq (sp) {
        .br {
          display: block;
        }
      }
    }

    &__philosophy-text {
      width: 60%;
      line-height: 1.6;
      margin: auto;
      font-weight: 500;

      @include mq () {
        width: 70%;
      }

      @include mq (sp) {
        width: 100%;
        text-align: left;

        .br {
          display: none;
        }

      }
    }

    &__thought {
      margin-bottom: 60px;
    }

    &__thought-title {
      letter-spacing: 0.04em;
    }

    &__thought-content {
      text-align: center;
      margin-bottom: 24px;
    }

    &__thought-main-title {
      margin-bottom: 16px;
    }

    &__thought-text {
      line-height: 1.6;
      font-weight: 500;

      @include mq (sp) {
        text-align: left;

        .br {
          display: none;
        }
      }
    }

    &__logo-image {
      max-width: 280px;
      margin: auto;
      margin-bottom: 24px;

      img {
        width: 100%;
      }

      @include mq (sp) {
        max-width: 240px;
      }
    }

    &__logo-text {
      text-align: center;
      margin-bottom: 24px;
      font-weight: 500;

      P {
        margin-bottom: 5px;
      }

      @include mq (sp) {
        text-align: left;
      }
    }

    // &__logo-content {
    //   display: flex;
    //   gap: 3%;
    // }

    &__logo-content-image {
      max-width: 320px;
      margin: auto;

      img {
        width: 100%;
      }

      @include mq (sp) {
        max-width: 280px;
      }
    }

    &__table {
      width: 80%;
      margin: auto;

      .br {
        display: none;
      }

      // th,
      // td {
      //   line-height: 1.2;
      //   vertical-align: middle;
      // }

      td {
        p {
          margin-bottom: 8px;
        }
      }

      @include mq () {
        width: 100%;

        .br {
          display: block;
        }
      }

    }




  }
}