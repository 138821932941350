@use "../global" as*;

.l-footer {
  border-top: 3px solid $clblack;
  padding-bottom: 20px;

  &__nav {
    padding: 30px 24px;
    border-bottom: 3px solid $clblack;

    @include mq (sp) {
      display: none;
    }
  }

  &__nav-list {
    display: flex;
    justify-content: center;
    gap: 24px;
    list-style: none !important;

    li {
      a {
        font-weight: 700;
      }
    }
  }

  &__contact {
    text-align: center;
    font-weight: 700;
    padding: 40px 24px 20px;

    p {
      margin-bottom: 6px;
    }


    address {
      line-height: 1.25;
      font-size: 1.6rem;
    }
  }

  &__ohsaka {
    margin-bottom: 20px;

    p {
      color: $clblue;
    }
  }

  &__hiroshima {
    p {
      color: $clred;

    }

  }

  &__tel {
    margin-bottom: 20px;

    a {
      background-color: $clblue;
      padding: 8px;
      display: block;
      width: 160px;
      color: $clW;
      border-radius: 3px;
      margin: auto;
      text-align: center;
    }
  }

  &__logo {
    max-width: 300px;
    margin: auto;
    margin-bottom: 40px;

    img {
      width: 100%;
    }

    @include mq (sp) {
      max-width: 240px;
      margin-bottom: 30px;
    }
  }

  &__copyright {
    display: block;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 700;
  }

}