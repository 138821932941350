@use "../global" as*;

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
    scroll-padding-top: 140px;
}


body {
    box-sizing: border-box;
    position: relative;
    font-family: 'Zen Kaku Gothic New', sans-serif;
    font-size: 1.6rem;
    line-height: 1;
    color: $clText;
    font-weight: 400;
    background-color: $clW;
    overflow-x: hidden;

    @include mq(sp) {
        font-size: 1.4rem;
    }
}

a {
    color: $clText;
    transition: 0.5s;

    &:hover {
        opacity: 0.4;

        img {
            opacity: 0.4;
        }
    }
}

p {
    line-height: 1.6;
}

a[href*="tel:"] {
    pointer-events: none;
    cursor: default;

    @include mq(sp) {
        pointer-events: all;
        cursor: pointer;
        text-decoration: none;
    }
}

img {
    height: auto;
    display: block;
}



// * {
//   outline: 1px solid red !important;
// }