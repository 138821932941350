@use "../../global" as *;

.lower {
  .p-lower-service {
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 6%;
    }

    &__inner {
      width: 47%;
      margin-bottom: 60px;

      @include mq (sp) {
        width: 100%;
        margin-bottom: 40px;

      }
    }

    &__image {
      margin-bottom: 24px;

      img {
        width: 100%;
        border-radius: 10px;
      }

    }

    .jakee {
      width: 100%;

      p {
        margin-bottom: 16px;
      }
    }

    .jakee__inner {
      width: 100%;
    }

    .jakee__image {
      display: flex;
      flex-wrap: wrap;
      gap: 2%;
      margin-bottom: 20px;

      @include mq (sp) {
        margin-bottom: 16px;
      }
    }

    .jakee__image-content {
      width: 47%;
      margin-bottom: 2%;
    }


  }
}