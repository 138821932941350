@use "../../global" as *;

.lower {
  .p-lower-dh-rework {
    &__about {
      text-align: center;

      .main-text {
        margin-bottom: 24px;
        line-height: 2;
        font-size: 2.4rem;

        @include mq (sp) {
          margin-bottom: 16px;
          font-size: 2.0rem;
        }
      }

      .sab-text {
        line-height: 1.5;
        font-size: 1.8rem;

        .br {
          display: none;
        }

        @include mq (sp) {
          font-size: 1.8rem;

          .br {
            display: block;
          }
        }
      }

      .br {
        display: none;

        @include mq (tb) {
          display: block;
        }
      }
    }

    &__flow {
      &-list {
        display: flex;
        justify-content: center;
      }

      ol {
        margin-bottom: 30px;
        font-weight: 700;
        font-size: 2.0rem;

        @include mq (sp) {
          margin-bottom: 20px;
        }

        li {
          margin-bottom: 32px;

          @include mq (sp) {
            margin-bottom: 20px;
          }

          &:last-child {
            margin-bottom: 0;
          }

          span {
            margin-right: 10px;
          }

          .text {
            margin-bottom: 24px;
            font-size: 2.0rem;
            font-weight: 700;
            display: flex;
            justify-content: center;

            @include mq (sp) {
              font-size: 1.6rem;
              margin-bottom: 16px;

              .br {
                display: none;
              }
            }
          }

          .image {
            max-width: 450px;
            margin: auto;

            img {
              width: 100%;
            }

          }

          &:after {
            content: "";
            display: block;
            width: 0;
            height: 0;
            margin: auto;
            border-left: 24px solid transparent;
            border-right: 24px solid transparent;
            border-top: 24px solid $clred;
            margin-top: 32px;

            @include mq (sp) {
              border-left: 18px solid transparent;
              border-right: 18px solid transparent;
              border-top: 18px solid $clred;
              margin-top: 20px;
            }
          }

          &:last-child::after {
            display: none;
          }
        }

        li::marker {
          color: $clred;
          text-align: center;
        }
      }

      .sub-text {
        text-align: center;

        .br {
          display: none;
        }

        @include mq (sp) {
          .br {
            display: block;
          }
        }
      }
    }

    &__area {
      &-list {
        display: flex;
        justify-content: center;

        ul {
          display: flex;
          justify-content: center;
          gap: 32px;
          margin-bottom: 20px;

          @include mq (sp) {
            flex-direction: column;
            align-items: center;
            gap: 14px;
          }

          li {
            font-weight: 700;
            font-size: 1.8rem;
            margin-bottom: 0;

            @include mq (sp) {
              font-size: 1.6rem;
            }

            .br {
              display: none;

              @include mq (sp) {
                display: block;
              }
            }
          }

          li::marker {
            color: $clblue;
          }
        }
      }

      .text {
        text-align: center;

        .br {
          display: none;
        }

        @include mq (sp) {
          .br {
            display: block;
          }
        }
      }

    }


    &__area-destination-list {
      li {
        margin-bottom: 17px !important;

        &:last-child {
          margin-bottom: 0 !important;
        }

        P {
          margin-bottom: 7px;
        }

        a {
          display: block;
          width: 100%;
          max-width: 180px;
          position: relative;

          &:after {
            content: "";
            display: inline-block;
            background-image: url(../img/common/arrow-red.svg);
            background-size: contain;
            background-repeat: no-repeat;
            width: 6px;
            height: 11px;
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translate(-50%, -50%);
          }


        }

      }


    }

    &__area-destination-text {
      margin-top: 20px;
      margin-bottom: 8px;

    }

    &__area-destination-contact {
      line-height: 1.4;
    }

    &__contact {
      p {
        margin-bottom: 14px;
      }
    }

    // .formTable {
    //   width: 100%;

    //   tr {
    //     border-bottom: 1px solid $clBD;
    //     width: 100%;
    //     margin-top: 1.5em;
    //     display: flex;
    //     align-items: center;
    //     padding-bottom: 24px;

    //     @include mq (tb) {
    //       flex-direction: column;

    //     }


    //     th {
    //       letter-spacing: .15em;
    //       width: 30%;

    //       &:last-child {
    //         margin-top: 0;
    //       }

    //       .required {
    //         font-size: 80%;
    //         padding: 4px 8px;
    //         border-radius: 2px;
    //         background-color: $clTextR;
    //         color: $clW;
    //         line-height: 0;
    //         font-weight: normal;
    //         margin-left: 10px;
    //       }

    //       @include mq (tb) {
    //         width: 100%;
    //         margin-bottom: 16px;

    //       }
    //     }

    //     td {
    //       display: block;
    //       width: 70%;

    //       @include mq (tb) {
    //         width: 100%;
    //       }

    //       input,
    //       select {
    //         border: 1px solid $clBD;
    //         width: 100%;
    //         padding: 8px 12px;
    //         border-radius: 3px;
    //         margin: 0;
    //         letter-spacing: .08em;
    //         max-width: 100%;
    //         height: 40px;
    //       }

    //       textarea {
    //         border: 1px solid $clBD;
    //         width: 100%;
    //         padding: 8px 12px;
    //         border-radius: 3px;
    //         margin: 0;
    //         letter-spacing: .08em;
    //       }

    //       input[type="checkbox"],
    //       input[type="radio"] {
    //         width: auto;
    //         margin-right: 8px;
    //       }

    //       ul {
    //         list-style: none;

    //         li {
    //           p {
    //             margin-bottom: 12px;
    //           }
    //         }
    //       }

    //       select {
    //         width: 100%;
    //         margin: 0;
    //         box-sizing: border-box;
    //         appearance: none;
    //         line-height: 1.6;
    //       }
    //     }

    //     .dropdown {
    //       position: relative;

    //       &:after {
    //         content: "";
    //         display: block;
    //         width: 0;
    //         height: 0;
    //         margin: auto;
    //         border-left: 8px solid transparent;
    //         border-right: 8px solid transparent;
    //         border-top: 8px solid $clblack;
    //         margin-top: 24px;
    //         position: absolute;
    //         top: 50%;
    //         right: 1em;
    //         margin-top: -4px;
    //         z-index: 2;
    //         pointer-events: none;
    //       }

    //     }

    //   }

    // }

    // .formBtn {
    //   text-align: center;
    //   padding-top: 1.5em;
    //   padding-bottom: 1.5em;
    //   width: 50%;
    //   margin: auto;

    //   input[type="button"],
    //   input[type="reset"],
    //   input[type="submit"] {
    //     display: block;
    //     font-size: 1.15em;
    //     font-weight: normal;
    //     background-color: $clblack;
    //     color: $clW;
    //     cursor: pointer;
    //     padding: 20px 40px;
    //     border-radius: 0;
    //     border: none;
    //     letter-spacing: .3em;
    //     text-indent: .5em;
    //     width: 100%;
    //     margin-bottom: 1em;
    //     margin-left: auto;
    //     margin-right: auto;
    //   }

    //   @include mq (sp) {
    //     width: 100%;
    //   }
    // }

    // //confirm
    // .confirm__text {
    //   margin-bottom: 24px;
    //   text-align: center;

    //   @include mq (sp) {
    //     text-align: left;
    //   }

    // }

    // .confirm__error-text {
    //   p {
    //     margin-bottom: 8px;
    //     text-align: center;
    //   }
    // }

    // // thanks
    // .mainTxt {
    //   text-align: center;
    //   margin-bottom: 2.5em;

    //   @include mq (sp) {
    //     text-align: left;

    //     .br {
    //       display: none;
    //     }
    //   }
    // }


    // .thanksError {
    //   padding: 24px;
    //   text-align: center;

    //   @include mq (sp) {
    //     text-align: left;
    //   }

    //   .thanksError-main-text {
    //     margin-bottom: 16px;
    //   }

    //   .thanksError-list {
    //     display: flex;
    //     justify-content: center;
    //   }

    //   ul {
    //     margin-left: 1.5em;
    //     margin-bottom: 16px;

    //     li {
    //       margin-bottom: 5px;
    //     }

    //     li::marker {
    //       color: $clred;
    //     }
    //   }
    // }



  }
}