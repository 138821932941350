@use "../../global" as *;

.lower {
  .p-lower-seminar {
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 32px 6%;
    }

    &__inner {
      width: 47%;

      // @include mq () {
      //   width: 47%;
      //   margin-bottom: 24px;

      // }
      @include mq (sp) {
        width: 100%;
      }
    }

    &__image {
      margin-bottom: 24px;

      img {
        width: 100%;
        border-radius: 10px;
      }

    }
    &-content__image {
      img {
        width: 100%;
        border-radius: 10px;
      }
    }
    &-content__text {
      text-align: center;
      font-weight: 700;
      margin-top: 24px;
      @include mq (sp) {
        text-align: left;
      }
    }

  }
}