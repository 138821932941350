@use "../../global" as *;

.lower {
  .p-lower-case {
    &__department {
      text-align: center;
      margin-bottom: 24px !important;
    }

    &__image {
      margin-bottom: 10px;
      border: 2px solid #222;
      border-radius: 5px;

      img {
        width: 100%;
        border-radius: 2.5px;
      }
    }

    &__container {
      display: flex;
      gap: 5%;
      justify-content: center;
      flex-wrap: wrap;
    }

    &__box {
      max-width: 250px;
      margin-bottom: 24px;

      p {
        font-weight: 500;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    // .tab {
    //   margin: 0 auto;
    //   margin-top: 120px;
    // }

    /* タブメニュー */
    .tab__menu {
      display: flex;
      // flex-wrap: wrap;
      gap: 2px 0.5%;
      margin-bottom: 3px;
      margin-left: 0;
    }

    .tab__menu-item {
      list-style: none;
      width: 33%;
      padding: 8px 5px;
      /* メニューに高さを付ける */
      text-align: center;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      cursor: pointer;
      transition: all .3s;
      margin-bottom: 0;
      /* アニメーション */
      border: 2px solid $clblack;

      // @include mq (sp) {
      //   width: 49.7%;
      // }
    }


    /* is-activeがついている時のスタイル */
    .tab__menu-item.is-active {
      background-color: $clblue;
      color: $clW;
    }

    /* タブパネル */
    .tab__panel {
      width: 100%;
      border: 2px solid $clblack;
    }

    .tab__panel-box {
      padding: 48px 24px 24px;
      border-radius: 10px;
    }

    .tab__panel-box001 {
      display: none;
    }

    .tab__panel-box002 {
      display: none;
    }

    .tab__panel-box003 {
      display: none;
    }

    .tab__panel-box004 {
      display: none;
    }

    .tab__panel-box005 {
      display: none;
    }

    /* is-showがついている時のスタイル */
    .tab__panel-box.is-show {
      display: block;
    }


  }
}