@use "../../global" as *;

.index {
  .p-service {
    padding-top: 70px;
    padding-bottom: 70px;
    border-bottom: 3px solid $clblack;

    @include mq (sp) {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    &__wrapper {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      max-width: 960px;
      margin: auto;
      gap: 24px;


    }

    &__content {
      a {
        border: 2px solid $clblack;
        display: block;
        width: 164px;
        height: 164px;
        border-radius: 12px;
        padding: 14px 0;
      }

      h3 {
        font-size: 1.6rem;
        color: $clblue;
        font-weight: 700;
        text-align: center;
        height: 40px;
        line-height: 1.1;
        margin-bottom: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      @include mq (sp) {
        a {
          width: 140px;
          height: 140px;
          padding: 11px 0;
        }

        h3 {
          font-size: 1.4rem;
          margin-bottom: 16px;
          height: 35px;

        }
      }
    }

    &__icon {
      margin: auto;

      img {
        width: 100%;
      }
    }

    &__icon-01 {
      width: 100px;

      @include mq (sp) {
        width: 82px;
      }
    }

    &__icon-02 {
      width: 76px;

      @include mq (sp) {
        width: 61px;
      }
    }

    &__icon-03 {
      width: 80px;

      @include mq (sp) {
        width: 61px;
      }
    }

    &__icon-04 {
      width: 77px;

      @include mq (sp) {
        width: 58px;
      }
    }

    &__icon-05 {
      width: 86px;

      @include mq (sp) {
        width: 69px;
      }
    }


  }
}