@use "../../global" as *;

.index {

  .p-kv {
    position: relative;
    margin-top: 130px;

    // border-bottom: 3px solid $clblack;
    &:after {
      content: "";
      display: block;
      border-bottom: 3px solid $clblack;
      position: absolute;
      bottom: 0;
      width: 100%;


    }

    &__copy {
      width: 45%;
      margin: auto;

      img {
        width: 100%;
      }

      @include mq (sp) {
        width: 85%;
        margin: auto;
        margin-bottom: 30px;
      }
    }


    &__image {
      max-width: 1340px;
      margin: auto;

      img {
        width: 100%;
      }

    }

    @include mq (sp) {
      margin-top: 100px;
    }
  }
}