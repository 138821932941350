@use "../../global" as *;

.c-table-style {
    width: 100%;
    border-collapse: separate;
    margin: 0.5em 0;
    border: 2px solid $clblack;
    border-radius: 8px;

    th,
    td {
        line-height: 1.2;
        vertical-align: middle;
    }

    th {
        text-align: center;
        padding: 12px 8px;
        width: 35%;
        border-bottom: 2px solid $clblack;
        border-right: 2px solid $clblack;

        &:last-child {
            border-bottom: none;
        }
    }

    td {
        text-align: left;
        padding: 12px 20px;
        border-bottom: 2px solid $clblack;
        font-weight: 700;
    }

    .nowrap {
        white-space: nowrap;
    }

    .border-b-none {
        border-bottom: none;
    }

    @include mq() {
        th {
            padding-left: 24px;
            padding-right: 24px;
            width: 30%;
        }

        td {
            padding-left: 24px;
            padding-right: 24px;
        }
    }

    @include mq(pc) {
        width: 80%;
        margin: auto;

        th {
            padding-left: 8px;
            padding-right: 8px;
        }

        td {
            padding-left: 12px;
            padding-right: 12px;
        }
    }
}