@use "../../global" as *;

.c-h2-style {
  font-size: 2.4rem;
  color: $clText;
  font-weight: 700;
  margin-bottom: 32px;


  @include mq (sp) {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
}

.c-h2-blue {
  display: inline-block;
  position: relative;

  &:before {
    content: "";
    display: inline-block;
    background-image: url(../img/common/decoration_blue.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 22px;
    height: 22px;
    position: absolute;
    left: -32px;
    top: 50%;
    transform: translate(0%, -50%, );

  }

  @include mq (sp) {
    &:before {
      width: 18px;
      height: 18px;
      left: -28px;
    }

  }
}

.c-h2-red {
  display: inline-block;
  position: relative;


  &:before {
    content: "";
    display: block;
    background-image: url(../img/common/decoration_red.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 22px;
    height: 22px;
    position: absolute;
    left: -32px;
    top: 50%;
    transform: translate(0%, -50%, );
  }

  @include mq (sp) {
    &:before {
      width: 18px;
      height: 18px;
      left: -28px;
    }

  }
}