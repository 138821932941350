@use "../../global" as *;

.lower {
  .p-lower-access {
    &__wrapper {
      width: 70%;
      margin: auto;

      @include mq (sp) {
        width: 100%;
      }

    }

    &__map {
      width: 80%;
      margin: auto;

      iframe {
        width: 100%;
        height: 300px;
      }

      @include mq (sp) {
        width: 100%;
      }
    }

    table {
      width: 80%;
      margin: auto;
      margin-bottom: 24px;

      @include mq (sp) {
        width: 100%;
      }
    }

    // &__buttn {
    //   max-width: 300px;
    //   margin: auto;
    //   transform: translate(0%, -50%);

    //   a {
    //     margin-top: 0;
    //   }
    // }
  }
}