@use "../../global" as *;

.lower {
  .page-title {
    padding: 80px 0px;
    margin-top: 96px;
    position: relative;
    border-bottom: 3px solid $clblack;

    h2 {
      font-size: 2.4rem;
      text-align: center;
      margin: auto;
      letter-spacing: 0.04em;
      font-weight: 700;
      line-height: 1.5;

    }

    p {
      text-align: center;
      color: $clblue;
      font-size: 2.2rem;
      margin-top: 8px;
      font-weight: 700;
      letter-spacing: 0.04em;
      line-height: 1;
    }

    .brSP {
      display: none;
    }

    @include mq (pcS) {
      border-radius: 0;

      &:before {
        border-radius: 0;
      }

    }

    @include mq (sp) {
      padding: 60px 12px 60px;
      margin: 58px 0px 40px;


      h2 {
        font-size: 1.8rem;
      }

      .brSP {
        display: block;
      }
    }
  }

  .l-header__nav-list {
    margin-left: 0;
  }

  .section-body {
    max-width: 960px;
    margin: auto;
    margin-bottom: 80px;
    margin-top: 60px;

    @include mq(tb) {
      max-width: 680px;
    }
  }

  h3 {
    font-size: 2.4rem;
    color: $clText;
    font-weight: 700;
    margin-bottom: 32px;

    @include mq(sp) {
      font-size: 2.0rem;
      margin-bottom: 24px;

    }
  }

  h4 {
    font-size: 2.0rem;
    font-weight: 700;
    margin-bottom: 24px;
    text-align: center;


    @include mq (tb) {
      font-size: 1.8rem;
      // margin-bottom: 24px;
    }
  }

  h5 {
    font-size: 1.8rem;
    margin-bottom: 14px;
    font-weight: 700;

    @include mq (tb) {
      font-size: 1.6rem;
    }
  }


  ul {
    list-style: disc;
    margin-left: 1em;

    li {
      margin-bottom: 6px;
      line-height: 1.6;


    }

    li::marker {
      color: $clblue;

    }

    strong {
      display: inline-block;
      margin-bottom: 6px;
    }
  }

  .list-blue {
    li::marker {
      color: $clblue;

    }
  }

  .list-red {
    li::marker {
      color: $clred;

    }
  }

  ol {
    li {
      margin-bottom: 16px;
    }
  }
}