@use "../../global" as *;

.lower {
  .p-lower-meo {

    &__sumaho {
      width: 30%;
      margin-bottom: 24px;
      margin: auto;
      margin-bottom: 30px;

      img {
        width: 100%;
        border-radius: 10px;
      }

      @include mq (sp) {
        width: 60%;
      }

    }

    &__text {
      width: 70%;
      margin: auto;
      line-height: 1.6;

      p {
        margin-bottom: 8px;
      }

      @include mq (tb) {
        width: 70%;
        margin: auto;
      }

      @include mq (sp) {
        width: 100%;
        max-width: 420px;
      }
    }

    // &__inner {
    //   display: flex;
    //   gap: 3%;

    //   @include mq (tb) {
    //     flex-direction: column;
    //   }
    // }

    &__image {
      width: 40%;
      max-width: 540px;

      @include mq (tb) {
        width: 70%;
        max-width: 420px;
        margin: auto;
        margin-bottom: 24px;
      }

      @include mq (sp) {
        width: 100%;
        max-width: 420px;
        margin-bottom: 24px;
      }

      img {
        width: 100%;
      }
    }

    &__table {
      width: 70%;
      margin: auto;
      margin-bottom: 16px;

      @include mq (sp) {
        width: 100%;
      }
    }

    &__table-text {
      text-align: right;
      width: 70%;
      margin: auto;
      margin-bottom: 8px;

      @include mq (sp) {
        width: 100%;
      }
    }

  }
}