@use "../../global" as *;

.index {

  .p-news {
    position: relative;
    padding-top: 60px;
    padding-bottom: 60px;
    border-bottom: 3px solid $clblack;

    @include mq (sp) {
      padding-top: 40px;
      padding-bottom: 40px;
    }



    .c-h2-style {
      margin-left: 30px
    }

    // @include mq () {
    //   margin-top: 20px;
    // }

    // @include mq (sp) {
    //   margin-bottom: 60px;
    // }

    &__wrapper {
      max-width: 560px;
      width: 100%;
      margin: auto;
    }



    &__feed-list {


      li {
        margin-bottom: 12px;
        padding-bottom: 12px;
        display: flex;
        border-bottom: 2px solid $clred;
        font-weight: 700;

        &:last-child {
          margin-bottom: 0;
        }
      }

      @include mq (tb) {
        width: 100%;

        li {
          flex-direction: column;
        }

      }


      .date {
        padding-right: 20px;
        position: relative;
        font-weight: 700;
        letter-spacing: 0.05em;

        &:after {
          content: "";
          display: inline-block;
          background-color: $clblue;
          width: 8px;
          height: 8px;
          border-radius: 100%;
          position: absolute;
          right: 0px;
          top: 50%;
          transform: translate(50%, -50%);
        }

      }

      .ttl {
        padding-left: 20px;
        font-weight: 700;
        letter-spacing: 0.05em;

        a {
          font-size: 1.6rem;
          display: block;
          max-width: 480px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

      }


      @include mq (tb) {
        .date {
          margin-bottom: 8px;

          &:after {
            display: none;
          }
        }

        .ttl {
          padding-left: 0;

          a {
            max-width: 100%;
          }
        }

        @include mq (sp) {
          .ttl a {
            font-size: 1.4rem;
          }

        }
      }

    }

  }
}