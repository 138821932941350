@use "../../global" as *;

.lower {
  .formTable {
    width: 100%;

    tr {
      border-bottom: 1px solid $clBD;
      width: 100%;
      margin-top: 1.5em;
      display: flex;
      align-items: center;
      padding-bottom: 24px;

      @include mq (tb) {
        flex-direction: column;

      }


      th {
        letter-spacing: .15em;
        width: 33%;
        line-height: 1.5;

        small {
          letter-spacing: 0.08em;
        }

        &:last-child {
          margin-top: 0;
        }

        .required {
          font-size: 80%;
          padding: 4px 8px;
          border-radius: 2px;
          background-color: $clTextR;
          color: $clW;
          line-height: 0;
          font-weight: normal;
          margin-left: 10px;
        }

        @include mq (tb) {
          width: 100%;
          margin-bottom: 16px;

        }
      }

      td {
        display: block;
        width: 70%;

        @include mq (tb) {
          width: 100%;
        }

        input,
        select {
          border: 1px solid $clBD;
          width: 100%;
          padding: 8px 12px;
          border-radius: 3px;
          margin: 0;
          letter-spacing: .08em;
          max-width: 100%;
          height: 40px;
        }

        textarea {
          border: 1px solid $clBD;
          width: 100%;
          padding: 8px 12px;
          border-radius: 3px;
          margin: 0;
          letter-spacing: .08em;
        }

        input[type="checkbox"],
        input[type="radio"] {
          width: auto;
          margin-right: 8px;
        }

        ul {
          list-style: none;
          margin-left: 0;

          li {
            p {
              margin-bottom: 12px;
            }
          }
        }

        select {
          width: 100%;
          margin: 0;
          box-sizing: border-box;
          appearance: none;
          line-height: 1.6;
        }

        .formM {
          max-width: 50%;

          @include mq () {
            max-width: 100%;
          }
        }
      }

      .radio {
        label {
          display: flex;
          align-items: center;
          height: 24px;

          input {
            width: 12px;
            height: 12px;
          }

          p {
            font-weight: 700;
            margin-bottom: 0;
          }

          @include mq () {
            input {
              width: 10px;
              height: 10px;
              right: 6px;
            }
          }
        }
      }

      .dropdown {
        position: relative;

        &:after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          margin: auto;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 8px solid $clblack;
          position: absolute;
          top: 50%;
          right: 51%;
          transform: translate(-50%, -50%);
          z-index: 2;
          pointer-events: none;
        }

        @include mq () {
          &:after {
            right: 1%;
          }
        }

      }

    }

  }

  .formBtn {
    text-align: center;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    width: 50%;
    margin: auto;

    input[type="button"],
    input[type="reset"],
    input[type="submit"] {
      display: block;
      font-size: 1.15em;
      font-weight: normal;
      background-color: $clblack;
      color: $clW;
      cursor: pointer;
      padding: 20px 40px;
      border-radius: 0;
      border: none;
      letter-spacing: .3em;
      text-indent: .5em;
      width: 100%;
      margin-bottom: 1em;
      margin-left: auto;
      margin-right: auto;
    }

    @include mq (sp) {
      width: 100%;
    }
  }

  //confirm
  .confirm__text {
    margin-bottom: 24px;
    text-align: center;

    @include mq (sp) {
      text-align: left;
    }

  }

  .confirm__error-text {
    p {
      margin-bottom: 8px;
      text-align: center;
    }
  }

  // thanks
  .mainTxt {
    text-align: center;
    margin-bottom: 2.5em;

    @include mq (sp) {
      text-align: left;

      .br {
        display: none;
      }
    }
  }


  .thanksError {
    padding: 24px;
    text-align: center;

    @include mq (sp) {
      text-align: left;
    }

    .thanksError-main-text {
      margin-bottom: 16px;
    }

    .thanksError-list {
      display: flex;
      justify-content: center;
    }

    ul {
      margin-left: 1.5em;
      margin-bottom: 16px;

      li {
        margin-bottom: 5px;
      }

      li::marker {
        color: $clred;
      }
    }
  }

}