@use "../../global" as *;

.lower {
  .p-error {
    &__button {
      a {
        &:after {
          left: 20px;
          transform: translate(-50%, -50%)rotate(-180deg);
        }
      }

    }

    &__content {
      text-align: center;
      line-height: 2;
      padding: 0 24px;
    }

    &__text {
      margin-bottom: 40px;
      letter-spacing: 0.04em;
      font-weight: 700;

      p {
        line-height: 1.8;
      }

    }
  }
}