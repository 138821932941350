@use "../../global" as *;

.index {

  .p-about {
    padding-top: 70px;
    padding-bottom: 70px;
    border-bottom: 3px solid $clblack;

    @include mq (sp) {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    h2 {
      font-size: 3.6rem;
      font-weight: 500;
      line-height: 1.4;
      text-align: center;
      margin-bottom: 46px;

      .br {
        display: none;
      }

      @include mq () {
        font-size: 2.8rem;
      }

      @include mq (sp) {
        font-size: 2.4rem;
        margin-bottom: 24px;

        .br {
          display: block;
        }

        line-height: 1.6;
      }
    }

    &__image {
      max-width: 600px;
      margin: auto;
      margin-bottom: 30px;

      img {
        width: 100%;
      }

      @include mq () {
        max-width: 480px;
      }
    }

    &__text {
      max-width: 680px;
      margin: auto;

      p {
        font-size: 2.0rem;
        font-weight: 500;
        margin-bottom: 20px;
      }

      @include mq () {
        max-width: 540px;

        p {
          font-size: 1.8rem;
          margin-bottom: 12px;
        }
      }

      @include mq (sp) {
        p {
          font-size: 1.6rem;
          line-height: 1.45;
        }

      }
    }

  }
}