@use "../../global" as *;

.c-fade-in {
    opacity: 0;
    transition-duration: 600ms;
    transition-property: opacity, transform;
}


.c-fade-in-up {
    transform: translate(0, 50px);
}
.c-scroll-in {
    opacity: 1;
    transform: translate(0, 0);
  }