@use "../../global" as *;

.c-loading {
    width: 100%;
    height: 100%;
    background-color: $clW;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;

    &__logo {
        max-width: 200px;
        transform-origin: bottom left;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        animation: logoRotate 1.5s linear infinite;
        @keyframes logoRotate {

            0%,
            100% {
                transform: rotate(5deg) translate(-50%, -50%);
            }

            50% {
                transform: rotate(-5deg) translate(-50%, -50%);
            }
        }

        img {
            width: 100%;
        }

    }
}

.c-loaded {
    opacity: 1;
    animation:  loaded 1s ease-in-out both;
    @keyframes loaded {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
            visibility: hidden;
        }
    }
}