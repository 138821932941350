$breakpoints: (
  'sp': "screen and (max-width:767px)",
  'tb': "screen and (max-width:1024px)",
  'pc': "screen and (max-width:1025px)",
  'pcS': "screen and (max-width:1280px)",
  'pcL': "screen and (max-width:1400px)",
) !default;


@mixin mq($breakpoint: tb) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

